import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Aboutuss from "../../containers/aboutus/Aboutus";
// import Certifications from "../../containers/certifications/Certifications";
// import { certifications } from "../../portfolio";
import "./AboutusComponent.css";
import { Fade } from "react-reveal";

class Aboutus extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="education-main">
        <Header theme={this.props.theme} />
        <div className="basic-education">
          <Fade bottom duration={2000} distance="40px">
            <div className="heading-div">
              <div className="heading-text-div">
                <h1 className="heading-text" style={{ color: theme.text }}>
                M/S. Mehul Harish Bhandarkar
                </h1>
                <h3 className="heading-sub-text" style={{ color: theme.text }}>
                Connected to do more
                </h3>
              </div>
              <div className="heading-img-div">
                <img
									src={require("../../assests/images/aboutus_workng.jpg")}
									alt=""M Bhandarkar Aboutus
								/>
              </div>
            </div>
          </Fade>
          <Aboutuss theme={this.props.theme} />
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Aboutus;
